.feature-text {
  text-align: left;
  width: 40vw;
  background-color: whitesmoke;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.75) 0px 5px 15px;
  padding: 2vw;
}

h2 {
  font-weight: bolder;
  font-size: calc(10px + 2vmin);
}

.number {
  color: var(--main-orange-color);
  margin-right: 1em;
}

.suffix {
  color: var(--main-orange-color);
}

.feature-content {
  font-size: calc(10px + 1vmin);
}
