.About {
  text-align: center;
}

.About-header {
  height: 550px;
  font-size: calc(10px + 2vmin);
  position: relative;
  background-image: url("../../assets/bay_area_lights.png");
  background-position: 20% 40%;
  background-size: cover;
  background-repeat: "no-repeat";
  margin-bottom: 70px;
}

.header-content {
  width: 100vw;
  color: white;
}

.born-in-the-bay-area-text {
  font-size: max(25px, min(calc(2px + 3vw), 40px));
  font-weight: bolder;
  text-align: left;
  padding-left: 8vw;
  width: 100vw;
  margin-top: -1vw;
}

.bay-area-text {
  color: var(--main-orange-color);
}

.about-the-product-container {
  width: min(300px, 50vw);
  margin: auto;
  margin-top: -100px;
  padding: 1vw 3vw 1vw 3vw;
  background-color: whitesmoke;
  display: flex;
  justify-content: center;
  box-shadow: var(--main-orange-color) 0px 5px 10px;
  position: relative;
  z-index: 2;
}

.about-the-product-container h3 {
  font-weight: bolder;
}

.mission-statement {
  width: min(600px, 75vw);
  margin: auto;
  margin-top: 50px;
}

.mission-statement h1 {
  font-weight: bolder;
}

.features-container {
  min-height: 20vh;
}

.main-features-text {
  font-size: calc(25px + 2vmin);
  text-align: left;
  font-weight: bolder;
  border-left: thick solid var(--main-orange-color);
  padding-left: 0.5em;
  margin-top: 75px;
  margin-left: 10vw;
}

.sportsleague-text {
  color: var(--main-orange-color);
}

.features-items {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 50px;
}

.feature-container {
  width: max(75vw, 100px);
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 20px;
}

.phone-model {
  width: min(50vw, 400px);
}
